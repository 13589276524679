<template>
  <div>
    <aForm
      :form="productForm"
      @onFieldsChange="updateTempProduct"
      @submit.prevent="submitProduct"
    >
      <aRow type="flex" :gutter="15">
        <aCol class="sidebar" flex="360px">
          <PackageSidebarSection
            v-if="
              tempProduct.id !== undefined &&
              ['edit', 'products-budget-edit'].includes($route.name)
            "
            :productForm="productForm"
            :tempProduct="tempProduct"
            :loadingSubmitProduct="loadingSubmitProduct"
            @onClickPublishProduct="onClickPublishProduct"
          />
          <PackageSidebarSection
            v-if="$route.name === 'new'"
            :productForm="productForm"
            :tempProduct="tempProduct"
            :loadingSubmitProduct="loadingSubmitProduct"
            @onClickPublishProduct="onClickPublishProduct"
          />
        </aCol>

        <aCol class="content" flex="auto">
          <PackageContentSection
            v-if="
              tempProduct.id !== undefined &&
              ['edit', 'products-budget-edit'].includes($route.name)
            "
            :productForm="productForm"
            :tempProduct="tempProduct"
          />
          <PackageContentSection
            v-if="$route.name === 'new'"
            :productForm="productForm"
            :tempProduct="tempProduct"
          />
        </aCol>
      </aRow>
    </aForm>

    <aButton
      v-if="$store.state.userData.id == 1"
      id="live-debug-inline"
      class="dev-btn mb-20 none"
      @click="devLogs"
      ><a-icon type="code-sandbox" />
    </aButton>
  </div>
</template>

<script>
// components
import PackageSidebarSection from "@/components/products/sections/package/PackageSidebarSection.vue";
import PackageContentSection from "@/components/products/sections/package/PackageContentSection.vue";

// reuse
import productReusables from "@/components/products/mixins/productReusables.js";

export default {
  name: "ProductPackagePage",
  mixins: [productReusables],
  components: { PackageSidebarSection, PackageContentSection },
  data() {
    return {
      productForm: this.$form.createForm(this, {
        onValuesChange: this.updateTempProduct,
      }),
      loadingSubmitProduct: false,
      productStatus: "draft",
    };
  },
  computed: {
    routeTab() {
      let type = "";
      if (this.$route.name === "new") type = `Novo Produto`;
      if (this.$route.name === "edit")
        type = `Produto ${
          this.product.details.id ? this.product.details.id : ""
        }`;
      if (this.$route.name === "products-budget-edit")
        type = `Orçamento ${
          this.product.details.id ? this.product.details.id : ""
        }`;

      return {
        title: `${type}`,
      };
    },
  },
  created() {
    if (this.$route.name === "products-budget-edit") this.isBudget = true;
    if (this.$route.name !== "new")
      this.getProductById(this.$route.params.id, false);
  },
  mounted() {
    if (this.$route.name === "new") {
      this.tempProduct.user = this.$store.state.userData;
      this.tempProduct.user_id = this.$store.state.userData.id;
    }
  },
  methods: {
    updateTempProduct(props, fields) {
      let field = fields ? Object.keys(fields)[0] : Object.keys(props)[0],
        value = fields ? Object.values(fields)[0] : Object.values(props)[0];

      this.tempProduct[field] = value;

      if (Array.isArray(value)) {
        this.tempProduct[field] = JSON.stringify(value);
      }
      this.tempProduct = { ...this.tempProduct };
    },
    updateOrCreateProduct(productType, action) {
      this.$http
        .post(`/${productType}/${action}`, this.tempProduct)
        .then(({ data }) => {
          this.$message.success(data.message);
          this.loadingSubmitProduct = false;
          if (action === "create")
            this.$tabs.close(`/products/new`, `/products/edit/${data.id}`);
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
          this.loadingSubmitProduct = false;
        });
    },
    onClickPublishProduct(status) {
      this.productStatus = status;
      this.submitProduct();
    },
    submitProduct() {
      this.productForm.validateFields((err, values) => {
        console.log(err, values);

        if (!err) {
          this.loadingSubmitProduct = true;

          this.tempProduct.modified_by = {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          };

          this.tempProduct.value = this.tempProduct.value
            ? this.tempProduct.value.replace(",", ".")
            : 0;

          if (this.productStatus === "published")
            this.tempProduct.status = this.productStatus;

          if (this.$route.name === "new")
            this.updateOrCreateProduct("product", "create");

          if (this.$route.name === "edit")
            this.updateOrCreateProduct("product", "update");

          if (this.$route.name === "products-budget-edit")
            this.updateOrCreateProduct("product-budget", "update");
        } else {
          let errNum = Object.keys(err).length;

          if (errNum === 1)
            this.$message.warning(
              "Atenção: Um campo obrigatório não foi preenchido. Verifique nas abas."
            );
          if (errNum > 1)
            this.$message.warning(
              `Atenção: ${errNum} campos obrigatórios não foram preenchidos. Verifique nas abas.`
            );
        }
      });
    },
    devLogs() {
      delete this.tempProduct?.company_branch?.logo;
      console.log(
        "devLogs: tempProduct",
        JSON.stringify(this.tempProduct, null, 4)
      );
    },
  },
};
</script>

<style lang="sass" scoped>
.sidebar
  float: left
  width: 360px
.content
  float: left
  width: calc(100% - 360px)
</style>
