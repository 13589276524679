<template>
  <div class="bg pd-20">
    <ProductEditPackagePage />
  </div>
</template>

<script>
import ProductEditPackagePage from "@/views/products/ProductEditPackagePage.vue";

export default {
  name: "ProductsPage",
  components: { ProductEditPackagePage },
};
</script>