var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('aForm',{attrs:{"form":_vm.productForm},on:{"onFieldsChange":_vm.updateTempProduct,"submit":function($event){$event.preventDefault();return _vm.submitProduct.apply(null, arguments)}}},[_c('aRow',{attrs:{"type":"flex","gutter":15}},[_c('aCol',{staticClass:"sidebar",attrs:{"flex":"360px"}},[(
            _vm.tempProduct.id !== undefined &&
            ['edit', 'products-budget-edit'].includes(_vm.$route.name)
          )?_c('PackageSidebarSection',{attrs:{"productForm":_vm.productForm,"tempProduct":_vm.tempProduct,"loadingSubmitProduct":_vm.loadingSubmitProduct},on:{"onClickPublishProduct":_vm.onClickPublishProduct}}):_vm._e(),_vm._v(" "),(_vm.$route.name === 'new')?_c('PackageSidebarSection',{attrs:{"productForm":_vm.productForm,"tempProduct":_vm.tempProduct,"loadingSubmitProduct":_vm.loadingSubmitProduct},on:{"onClickPublishProduct":_vm.onClickPublishProduct}}):_vm._e()],1),_vm._v(" "),_c('aCol',{staticClass:"content",attrs:{"flex":"auto"}},[(
            _vm.tempProduct.id !== undefined &&
            ['edit', 'products-budget-edit'].includes(_vm.$route.name)
          )?_c('PackageContentSection',{attrs:{"productForm":_vm.productForm,"tempProduct":_vm.tempProduct}}):_vm._e(),_vm._v(" "),(_vm.$route.name === 'new')?_c('PackageContentSection',{attrs:{"productForm":_vm.productForm,"tempProduct":_vm.tempProduct}}):_vm._e()],1)],1)],1),_vm._v(" "),(_vm.$store.state.userData.id == 1)?_c('aButton',{staticClass:"dev-btn mb-20 none",attrs:{"id":"live-debug-inline"},on:{"click":_vm.devLogs}},[_c('a-icon',{attrs:{"type":"code-sandbox"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }